const createToc = () => {

  const tocbot = require('tocbot/dist/tocbot.js')

  
  function camelize(text) {
    return text.replace(/[^\w\s]|_/g, "").replace(/\s/g, "").replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();        
    });
  }

  const tocElement = document.querySelector("[data-action='create-toc']");
  if(tocElement == null){return}
  const h2Elements = document.querySelectorAll("h2")

  for(const element of h2Elements){
    const elementText = element.textContent
    // Add an id to the h2
    if(!element.id) element.id = camelize(elementText)
  }

  tocbot.init({
    // Where to render the table of contents.
    tocSelector: '.js-toc',
    // Where to grab the headings to build the table of contents.
    contentSelector: '.js-toc-content',
    // Which headings to grab inside of the contentSelector element.
    headingSelector: 'h2'
    // headingsOffset: 75,
    // scrollSmoothOffset: -75
  });
}

export { createToc };