const deportClick = () => {
  const deportClickElements = document.querySelectorAll("[data-action='deported_click']");

  for(const element of deportClickElements){
    element.addEventListener('click', function(){
      const targetId = this.getAttribute('data-target');
      setTimeout(function() {
        document.querySelector(String(targetId)).click();
      }, 200);
    })
  }
}

export { deportClick };