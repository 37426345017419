const collapse = () => {
  let collapseElements = document.querySelectorAll("[data-toggle='collapse']")
  for(let element of collapseElements){
    element.addEventListener('click', function() {
      let targetArray = this?.dataset?.target?.split(",")
      for(let target of targetArray){
        document.querySelector(`#${target}`).classList.toggle("d-none")
        document.querySelector(`#${target}`).classList.toggle("d-flex")
      }
    })
  }
}

export { collapse };