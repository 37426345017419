const jsonPrettify = () => {
  const jsonPrettifyElements = document.querySelectorAll("[data-action*='json_prettify'")

  for(let element of jsonPrettifyElements){
    element.addEventListener('click', function(){
      const elementContainingData = this.parentNode.querySelector(".data-element")

      const jsonData = elementContainingData.getAttribute('data-full');
      let jsonPrettified = "ERROR WHILE PARSING JSON"
      let parsedJson

      try{
        let extractJsonOnly
        if(jsonData[0] != "["){
          extractJsonOnly = "{" + jsonData.substring(jsonData.indexOf("{") + 1);
        }else{
          extractJsonOnly = jsonData
        }
          parsedJson = JSON.parse(extractJsonOnly)
          console.log(parsedJson)
          jsonPrettified = JSON.stringify(parsedJson, null, 4)
      }catch(error){}


      // Adding info if article identified
      if(parsedJson?.created_at_ts && parsedJson?.user_updated_at_ts){
        if(Date.parse(parsedJson.created_at_ts) - Date.parse(parsedJson.user_updated_at_ts) > 6 *60 *60 *1000){
          jsonPrettified ="📛 article identifié\n"+jsonPrettified
        }
      }

      document.querySelector("#json-prettify-modal .modal-body").textContent = jsonPrettified;

      let flashDiv = `<div class="alert alert-info alert-dismissible fade show alert-autofade" style="z-index:20000; position: fixed; top: 5px; width: 100%;" onclick="this.remove()">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        Json copié dans la console !!
      </div>`
      document.querySelector("#flash_message").innerHTML = flashDiv
    })
  }
}

export { jsonPrettify };