const copyButton = () => {
  const copyButtonElements = document.querySelectorAll(".copyButton");

  for(let button of copyButtonElements){
    button.addEventListener('click',function(){
      navigator.clipboard.writeText(this.dataset.copy)

      let flashDiv = `<div class="alert alert-info alert-dismissible fade show alert-autofade" style="z-index:200; position: fixed; top: 5px; width: 100%;">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        Copié dans le presse-papier
      </div>`
      document.querySelector("#flash_message").innerHTML = flashDiv

      window.setTimeout(function() {
        $(".alert-autofade").fadeTo(500, 0).slideUp(500, function(){
          $(this).remove(); 
        });
      }, 4000);
    })
  }
}

export { copyButton };