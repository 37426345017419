import Rails from '@rails/ujs';

const ajaxSubmit = () => {

  let sponsorCategoryForms = document.querySelectorAll(".submit-on-change")
  if(sponsorCategoryForms){
    for(let form of sponsorCategoryForms){
      form.addEventListener('change', function(){
        Rails.fire(this, 'submit');
      })
    }
  }
}

export { ajaxSubmit };