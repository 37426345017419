const makeAlertAutofade = () => {
  window.setTimeout(function() {
    $(".alert-autofade").fadeTo(500, 0).slideUp(500, function(){
      $(this).remove(); 
    });
  }, 4000);

  document.querySelector(".alert-dismissible")?.addEventListener("click", function(){
    this.remove()
  })
}

window.makeAlertAutofade = makeAlertAutofade


export { makeAlertAutofade };