const detailedModal = () => {
  const detailedModalElements = document.querySelectorAll("[data-action*='detailed_modal']");

  for(const element of detailedModalElements){
    element.addEventListener('click', function(){
      const detailsToShow = this.getAttribute('data-details');
      const modalDetails = document.querySelector('#detailsModal #details');
      modalDetails.innerHTML = detailsToShow

      const titleToShow = this.getAttribute('data-title');
      if(titleToShow){
        const modalTitle = document.querySelector('#detailsModal #title');
        modalTitle.innerHTML = titleToShow
      }
    })
  }
}

export { detailedModal };